import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { Link } from 'react-router-dom';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import TrendButton from '../ui/Button';
import { LoginWrapper } from './UI/Form';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';
import { theme } from '../Theming/Theming';
import SiteContentContext from '../SiteContent/SiteContentContext';

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
`;

const actionWrapper = css`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    flex: 1 1 calc(50% - 1em);
  }
`;

const TextLink = styled(Link)`
  displtext-decoration: none;
  color: ${theme.colors.primary};
  margin-bottom: 1rem;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    display: block;
    margin-left: auto;
  }
  &.forgot-link {
    text-align: right;
  }
`;

function LogInPage() {
  const { routes } = useShopConfig();
  const { loginText } = useContext(SiteContentContext);
  return (
    <LoginWrapper>
      <Intl>{t => <Head data={{ title: t('Login') }} />}</Intl>
      <LogInFormProvider redirect="my-pages">
        {({ globalError, isSubmitting, isValid }) => (
          <section className="">
            <header
              style={{
                marginBottom: '0.75rem'
              }}
            >
              {loginText}
            </header>
            <TextLink to={routes.signup.path} className="signup-link">
              {t('Not yet a member? Sign Up')}
            </TextLink>
            <Input type="email" name="email" label={t('E-mail address')} />
            <Input type="password" name="password" label={t('Password')} />
            {globalError && (
              <GlobalError style={{ marginBottom: '2em' }}>
                {globalError}
              </GlobalError>
            )}

            <section className={actionWrapper}>
              <StyledTrendButton
                type="submit"
                disabled={!isValid}
                loading={isSubmitting}
                loadingText={t('Hold on a moment...')}
              >
                {t('Log in')}
              </StyledTrendButton>
              <TextLink className="forgot-link" to={routes.forgotPassword.path}>
                {t('Forgot password?')}
              </TextLink>
            </section>
          </section>
        )}
      </LogInFormProvider>
    </LoginWrapper>
  );
}

export default LogInPage;
